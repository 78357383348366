import React from 'react'
import './Pagination.css'

const PaginationComp = ({vidoesPerPage,totalVideos,paginate,nextPage,prevPage}) => {
    const pageNumbers=[];
    for (let i = 1; i <= Math.ceil(totalVideos / vidoesPerPage); i++) {
        pageNumbers.push(i);
      }
    return (
    <nav>
      <ul className='pagination'>
        {pageNumbers.map(number => (
          <li onClick={()=>paginate(number)} key={number} className='page-item'>
            <a className='page-link'>
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
    )
}

export default PaginationComp
