import React from 'react'
import './Main.css'

const Main = () => {
    return (
        <div className="main">
            <h1><strong>Sri Sri Gurudaasaa</strong></h1>
            <br></br>
            <h3><q>Every soul is Gurudaasaa</q></h3>
            <br></br>
            <h4 className="who">Who is Gurudaasaa?</h4>
            <p><strong>Sri Sri Gurudaasaa</strong> was born in a very orthodox Brahmin Family to Sri Vijayaraghavan and Smt.Alamelu.</p>
            <p><strong>Sri Sri Gurudaasaa</strong> was born in Mayiladuthurai, a spiritual town on the banks of river Cauvery. Mayiladuthurai is surrounded by cities and towns famous for ancient temples and pilgrimage.</p>
            <br></br>
            <p><strong>Sri Sri Gurudaasaa</strong>, born in a family with the lineage of great musicians, had the blessings of his mother, to be his first Sangeetha(Musical) Guru. The divine Ankitha Naama "Gurudaasaa" (pen name) was graced on him by three great Yathis.</p>
            <br></br>
            <p>Numerous Keerthanas in different languages flowed extempore like nectar from Sri Sri Gurudaasaa which have made its place in countless hearts. Great Musicians have sung and are singing these kritis which are popularly known as "Gurudaasaa Manjari".</p>
            <br></br>
            <p><strong>Sri Sri Gurudaasaa</strong> has composed Manjaris in Sanskrit, Tamil, Telugu, Kannada, Hindi and English. </p>
        </div>
    )
}

export default Main
