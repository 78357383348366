import React, { useState, useEffect } from "react";
import "./Video.css";
import ReactPlayer from "react-player";
import Pagination from "./Pagination";
import { useTrail, animated } from "react-spring";

const Video = () => {
  const [videos, setvideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage] = useState(10);
  const [currentpage, setcurrentpage] = useState(1);
  let videoArr=[];
  const fetchData = async (nextPageToken) => {
    try {
      let url = "https://www.googleapis.com/youtube/v3/search?key=AIzaSyCTazuz62CoFs4azd5t5qC_IRFB6wLVI3g&channelId=UCwrF1vMQwhCOMniCL7hVJyg&part=snippet,id&order=date&maxResults=50&";
        if (nextPageToken) {
          url += "pageToken=" + nextPageToken;
        } else {
          videoArr = [];
        }
        const response = await fetch(url);
        const json = await response.json();
        videoArr = videoArr.concat(json.items);
        if (json.nextPageToken) {
          fetchData(json.nextPageToken);
        } else {
          setvideos(videoArr);
          setLoading(false);
        }
      }
  catch (e) {
        console.error(e);
    }
  }
console.log(videos)

useEffect(()=>  {
  console.log('useeffect1')
  fetchData();
},[])


  const indexOfLastPost = currentpage * perPage;
  const indexOfFirstPost = indexOfLastPost - perPage;
  const currentPosts = videos.slice(indexOfFirstPost, indexOfLastPost);
  
  const paginate = (pageNumber) => setcurrentpage(pageNumber);
  const trail = useTrail(currentPosts.length, {
    from: { marginLeft: -20, opacity: 0, transform: "translate3d(0,-40px,0)" },
    to: { marginLeft: 0, opacity: 1, transform: "translate3d(0,0px,0)" },
  });
  
  return loading ? (
    <div className="lds-dual-ring"></div>
  ) : (
    <div className="video_main">
      <div>
        <Pagination
          paginate={paginate}
          vidoesPerPage={perPage}
          totalVideos={videos.length}
        />
      </div>
      <div className="video_content">
        {trail.map((props, index) => {
          return (
            <animated.div
              className="video_area"
              key={currentPosts[index]}
              style={props}
            >
              <p>{currentPosts[index].snippet.title}</p>
              {currentPosts[index].id.videoId?<ReactPlayer
                className="video_player"
                url={`https://www.youtube.com/watch?v=${currentPosts[index].id.videoId}`}
                width="40%"
                height="40%"
              />:
              <ReactPlayer
                className="video_player"
                url={`https://www.youtube.com/watch?v=${currentPosts[index].id.videoId}`}
                width="40%"
                height="40%"
              />
              }
            </animated.div>
          );
        })}
      </div>
    </div>
  );
};

export default Video;
