import React from 'react';
import './App.css';
import Header from './Header'
import Main from './Main'
import Back from './guru.jpg'
import Video from './Video'
import {BrowserRouter as Router,Route} from 'react-router-dom'

function App() {
  return (
    <div className="app">
    <Router>
    
    <Route path="/composition">
    <Header/>
      <Video />
    </Route>
    <Route exact path="/">
    <Header/>
      <img src={Back} alt="NA"/>
      <Main/>
    </Route>
      </Router>
    </div>
  );
}

export default App;
