import React from 'react'
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from "@material-ui/core/IconButton";
import './Header.css'
import {Link} from 'react-router-dom'
import {useSpring, animated} from 'react-spring'

const Header = () => {
    const props = useSpring({delay:90,opacity: 1, from: {opacity: 0}})
    return (
        <div className="header">
        <animated.div style={props} className="header_left">
            <p>Shree Sadguru Kataksham Bhavathu </p>
        </animated.div>
        <animated.div style={props} className="header_right">
        <Link to="/" style={{textDecoration:"none"}}>
            <IconButton>
            <div className="headerleft_options">
            <HomeIcon />
            <p>Home</p>
            </div>
            </IconButton>
        </Link>
        <Link to="/composition" style={{textDecoration:"none"}}>
            <IconButton>
            <div className="headerleft_options">
            <MusicNoteIcon />
            <p>Manjari Videos</p>
            </div>
            </IconButton>
        </Link>
            </animated.div>
            </div>)
}

export default Header
